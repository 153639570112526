import news1 from "../../Images/news-1.png"
import news2 from "../../Images/news-2.png"
import news3 from "../../Images/news-3.png"
import news4 from "../../Images/news-4.png"
import news5 from "../../Images/news-5.png"
import news6 from "../../Images/news-6.png"
import blog1 from "../../Images/blog-1.png";
import blog2 from "../../Images/blog-2.png";
import blog3 from "../../Images/blog-3.png";
import blog4 from "../../Images/blog-4.png";

export const BlogData = [
    {
        id : 1,
        title1 : "Newly Built Customized Hydro Plants",
        date : "December 8, 2020 - By : Admin",
        img : news1,
        img2 :blog1,
        para : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. by the readable content of a page when looking at its layout. normal distribu ssfr4 and tion of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."

    },
    {
        id : 2,
        title1 : "Home Windmill, Reuse & Maintenance",
        date : "December 8, 2020 - By : Admin",
        img : news2,
        img2 :blog2,
        para : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. by the readable content of a page when looking at its layout. normal distribu ssfr4 and tion of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."

    },
    {
        id : 3,
        title1 : "Avoid Solar Panels Damage Your Top Roof ",
        date : "December 8, 2020 - By : Admin",
        img : news3,
        img2 :blog3,
        para : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. by the readable content of a page when looking at its layout. normal distribu ssfr4 and tion of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
    },
    {
        id : 4,
        title1 : "The Emergence of New Tech for a Green Future",
        date : "December 8, 2020 - By : Admin",
        img : news4,
        img2 :blog4,
        para : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. by the readable content of a page when looking at its layout. normal distribu ssfr4 and tion of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
    },
    {
        id : 5,
        title1 : "Mead Before And After The Epic Etars",
        date : "December 8, 2020 - By : Admin",
        img : news5,
        img2 :blog1,
        para : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. by the readable content of a page when looking at its layout. normal distribu ssfr4 and tion of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
    },
    {
        id : 6,
        title1 : "We Re-shaping Energy For a Better Quality",
        date : "December 8, 2020 - By : Admin",
        img : news6,
        img2 :blog2,
        para : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. by the readable content of a page when looking at its layout. normal distribu ssfr4 and tion of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
    },

]