import client1 from "../../Images/client-1.png";
import client2 from "../../Images/client-2.png";
import client3 from "../../Images/client-3.png";
import client4 from "../../Images/client-4.png"
import client5 from "../../Images/client-5.png"
import client6 from "../../Images/client-6.png"

export const ReviewData = [
  {
    name: "Alexzander White",
    title: "XYZ Company CEO",
    img: client1,
    comment: "Awesome Services",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at est id leo luctus gravida a in ipsum Lorem ipsum dolor sit  amet",
  },
  {
    name: "Aleaha Annaday",
    title: "XYZ Company CEO",
    img: client2,
    comment: "Smart Team Work",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at est id leo luctus gravida a in ipsum Lorem ipsum dolor sit  amet",
  },
  {
    name: "Rodrick O’Brien",
    title: "XYZ Company CEO",
    img: client3,
    comment: " Very Fast Work Delivery ",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at est id leo luctus gravida a in ipsum Lorem ipsum dolor sit  amet",
  },
  {
    name: "Sunny Roy",
    title: "XYZ Company CEO",
    img: client4,
    comment: "Awesome Services",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at est id leo luctus gravida a in ipsum Lorem ipsum dolor sit  amet",
  },
  {
    name: "Alexzander White",
    title: "XYZ Company CEO",
    img: client5,
    comment: "Awesome Services",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at est id leo luctus gravida a in ipsum Lorem ipsum dolor sit  amet",
  },
  {
    name: "Mia Alaxzander",
    title: "XYZ Company CEO",
    img: client6,
    comment: "Awesome Services",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at est id leo luctus gravida a in ipsum Lorem ipsum dolor sit  amet",
  },
];
